import api from "../../utils/api";

export const Register = async (data) => {
    let res = await api.put("/industries/create", data, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
    return res?.data;
}
export const Update = async (data) => {
    let res = await api.post("/industries/update", data, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
    return res?.data;
}
export const Delete = async ({ id }) => {
    let res = await api.delete(`/industries/delete/${id}`, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
    return res?.data;
}


export const GetList = async () => {
    let res = await api.get("/industries/getList", { headers: { 'authorization': `${localStorage.getItem('token')}` } });
    return res?.data;
}
