import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UseDeleteIndustry } from "../../../hooks/industries";

const DeleteIndustry = ({ show, setShow, title, prev }) => {
  const mutation = UseDeleteIndustry(() => {
    setShow(false);
  });

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      backdrop="static"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title} Industry</Modal.Title>
      </Modal.Header>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate({ id: prev.id });
        }}
      >
        <Modal.Body className="text-center">
          Are you sure you want to remove this industry?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Remove
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteIndustry;
