import React from "react";
import SDGTrackerIcon from "../../asserts/users.svg";
import DashboardIcon from "../../asserts/dashboard.svg";
import REsIcon from "../../asserts/submitess.svg";
import Logo from "../../asserts/logo.png";
import { Link } from "react-router-dom";
import { LiaIndustrySolid } from 'react-icons/lia'
import { IoLogOutOutline } from 'react-icons/io5'
import { MdOutlineMailLock } from 'react-icons/md'
import "./sidenav.css";

const SideNav = () => {
  let setP = window.location.pathname;

  return (
    <div
      className="text-dark  py-4  "
      style={{
        backgroundColor: "#dfdfdf",
        borderRadius: "10px",
        height: "98vh",
      }}
    >
      <div className="d-flex mx-auto justify-content-center TeamableTop">
        <div>
          {" "}
          <img src={Logo} alt="" style={{ width: "170px" }} />
        </div>
      </div>
      <div
        className=" sidenavul border-0  "
        style={{ position: "relative", top: "10px", padding: "20px 0px" }}
      >
        <ul>
          <li>
            <Link
              className={`d-flex text-dark text-decoration-none ${setP === "/admin_panel" ? "setActivebg" : ""
                }`}
              to="/admin_panel"
            >
              <img
                src={DashboardIcon}
                alt="dashboard"
                style={{ width: "18px" }}
              />
              <p className="FiloSubMenu " style={{ marginLeft: "3px" }}>
                Funders
              </p>{" "}
            </Link>
          </li>
          <li>
            <Link
              className={`d-flex text-dark text-decoration-none ${setP === "/users_list" ? "setActivebg" : ""
                }`}
              to="/users_list"
            >
              {" "}
              <img src={SDGTrackerIcon} alt="" style={{ width: "18px" }} />
              <p className="FiloSubMenu mx-1">Users</p>{" "}
            </Link>
          </li>
          <li>
            <Link
              className={`d-flex text-dark text-decoration-none ${setP === "/deals_list" ? "setActivebg" : ""
                }`}
              to="/deals_list"
            >
              <img src={REsIcon} alt="projects" style={{ width: "15px" }} />
              <p className="FiloSubMenu mx-2">Deals</p>{" "}
            </Link>
          </li>
          <li>
            <Link
              className={`d-flex text-dark text-decoration-none ${setP === "/industries" ? "setActivebg" : ""
                }`}
              to="/industries"
            >
              <div><LiaIndustrySolid /></div>
              <p className=" mx-2 mb-0 FiloSubMenu">Industries</p>
            </Link>
          </li>
          <li>
            <Link
              className={`d-flex text-dark text-decoration-none ${setP === "/smtp" ? "setActivebg" : ""
                }`}
              to="/smtp"
            >
              <div><MdOutlineMailLock /></div>
              <p className=" mx-2 mb-0 FiloSubMenu">SMTP</p>
            </Link>
          </li>
          <li>
            <Link
              className={`d-flex text-dark text-decoration-none `}
              to="/"
              onClick={() => {
                localStorage.removeItem("token");
              }}
            >
              <IoLogOutOutline />
              <p className="FiloSubMenu mx-1">Logout</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
