import { useMutation, useQuery } from "@tanstack/react-query";
import { Delete, GetList, Register, Update } from "./endPoints";

import Toast from "../../components/Toast";
import { queryClient } from "../..";

export const UseAddIndustry = (SuccessCallback) => {
  const mutation = useMutation({
    mutationFn: Register,
    onSuccess: (data) => {
      if (data?.error) {
        Toast(data?.error, "error");
      } else {
        Toast("New Industry Has Been Added", "success");
        queryClient.invalidateQueries({ queryKey: ["IndustryList"] });

        if (SuccessCallback) SuccessCallback(data);
      }
    },
  });
  return mutation;
};

export const UseUpdateIndustry = (SuccessCallback) => {
  const mutation = useMutation({
    mutationFn: Update,
    onSuccess: (data, variables) => {
      if (data?.error) {
        Toast(data?.error, "error");
      } else {
        queryClient.invalidateQueries({ queryKey: ["IndustryList"] });
        Toast("Industry Has Been updated", "success");

        if (SuccessCallback) SuccessCallback(data);
      }
    },
  });
  return mutation;
};

export const UseDeleteIndustry = (SuccessCallback) => {
  const mutation = useMutation({
    mutationFn: Delete,
    onSuccess: (data) => {
      if (data?.error) {
        Toast(data?.error, "error");
      } else {
        Toast("Industry Has Been removed", "success");
        queryClient.invalidateQueries({ queryKey: ["IndustryList"] });

        if (SuccessCallback) SuccessCallback(data);
      }
    },
  });
  return mutation;
};

export const UseGetListIndustry = () => {
  const query = useQuery({
    queryKey: ["IndustryList"],
    queryFn: GetList,
  });
  return query;
};
