

import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Industries from './components/admin/Industries';
import Loader from './utils/Loader';
const Auth = lazy(() => import('./components/Auth'))
const Apply = lazy(() => import('./components/user/Apply'))
const AdminPanel = lazy(() => import('./components/admin/index'))
const UserList = lazy(() => import('./components/admin/UserList'))
const ResponseList = lazy(() => import('./components/admin/ResponseList'))
const UserResponseList = lazy(() => import('./components/user/ResponseList'))
const SMTP = lazy(() => import('./components/admin/SMTP'))

function App() {

  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer toastStyle={{ backgroundColor: "#0d2352", color: "white" }} />
      <Router>
        <Routes>

          <Route exact path="/" element={<Auth />} />
          <Route exact path="/apply" element={<Apply />} />
          <Route exact path="/admin_panel" element={<AdminPanel />} />
          <Route exact path="/users_list" element={<UserList />} />
          <Route exact path="/deals_list" element={<ResponseList />} />
          <Route exact path="/deal" element={<UserResponseList />} />
          <Route exact path="/industries" element={<Industries />} />
          <Route exact path="/smtp" element={<SMTP />} />



        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
