import React, { useState } from "react";
import { UseGetListIndustry } from "../../../hooks/industries";
import { BiEdit } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import AddIndustry from "./AddIndustry";
import DeleteIndustry from "./DeleteIndustry";
const IndustriesList = () => {
  const { data, isLoading } = UseGetListIndustry();
  const [showM, setShowM] = useState(false);
  const [showRM, setShowRM] = useState(false);
  const [prev, setPrev] = useState(null);
  return (
    <div className="">
      {data?.length > 0 &&
        data.map(
          (item, index) =>
            item && (
              <div key={index} className=" mb-3">
                <div className="shadow-sm rounded px-3 py-2 text-capitalize d-flex align-items-center justify-content-between">
                  <div>{item.name}</div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="border-0 p-0 m-0 me-2 bg-transparent "
                      onClick={() => {
                        setPrev(item);
                        setShowRM(true);
                      }}
                    >
                      <AiOutlineDelete className="text-danger" />
                    </button>

                    <button
                      type="button"
                      className="border-0 p-0 m-0  bg-transparent "
                      onClick={() => {
                        setPrev(item);
                        setShowM(true);
                      }}
                    >
                      <BiEdit className="text-primary" />
                    </button>
                  </div>
                </div>
              </div>
            )
        )}
      <AddIndustry
        setShow={() => {
          setShowM(false);
          setPrev(null);
        }}
        show={showM}
        title={"Update"}
        prev={prev}
      />
      <DeleteIndustry
        setShow={() => {
          setShowRM(false);
          setPrev(null);
        }}
        show={showRM}
        title={"Remove"}
        prev={prev}
      />
    </div>
  );
};

export default IndustriesList;
