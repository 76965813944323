import React from "react";
import SideNav from "./SideNav";

const Layout = ({ children }) => {
  return (
    <div className="row m-0 mt-2">
      <div className="col-lg-2 col-md-3 pe-0 mobile-mode2">
        <SideNav />
      </div>
      <div className="col-lg-10 col-md-9 ps-0 pb-5 vh-100 overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default Layout;
