import React from "react";

const Loader = ({ title = "Loading..." }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center vh-100 w-100  position-fixed top-0 start-0 "
      style={{ zIndex: 999999, background: "#ffffffa1" }}
    >
      <div className="mx-auto text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div>{title}</div>
      </div>
    </div>
  );
};

export default Loader;
