import { toast } from "react-toastify";
export default function Toast(message,type){
 
    const settings={
     
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    if(type=='success'){
        return toast.success(message, settings);
    }else if(type=="error"){
        return toast.error(message, settings);
    }
  }