import React, { useState } from "react";
import Layout from "../Layout";
import IndustriesList from "./IndustriesList";
import AddIndustry from "./AddIndustry";

const Industries = () => {
  const [showM, setShowM] = useState(false);
  return (
    <Layout>
      <div className="px-2">
        <div className="d-flex align-items-center justify-content-between px-4 py-2 rounded shadow-sm bg-white">
          <h4>Industry List</h4>
          <button className="btn btn-primary" onClick={() => setShowM(true)}>
            Add Industry
          </button>
        </div>
        <IndustriesList />
      </div>
      <AddIndustry setShow={setShowM} show={showM} title={"Add"} />
    </Layout>
  );
};

export default Industries;
