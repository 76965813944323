import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { UseAddIndustry, UseUpdateIndustry } from "../../../hooks/industries";

const AddIndustry = ({ show, setShow, title, prev }) => {
  const addMutation = UseAddIndustry(() => {
    setShow(false);
    setData("");
  });
  const updateMutation = UseUpdateIndustry(() => {
    setShow(false);
    setData("");
  });
  const [data, setData] = useState("");

  useEffect(() => {
    if (prev) setData(prev.name);
  }, [prev]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setData("");
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title} Industry</Modal.Title>
      </Modal.Header>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!prev) {
            addMutation.mutate({ name: data });
          } else {
            updateMutation.mutate({ name: data, id: prev.id });
          }
        }}
      >
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            maxLength={200}
            onChange={(e) => setData(e.target.value)}
            value={data}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddIndustry;
